import React, { useState, useEffect } from "react";

// Router
import { Link } from "react-router-dom";

// Components
import Header from "../Components/Header";

function ExpiredRoom() {
  const [bodyText, setBodyText] = useState();

  const uri = window.location.pathname;
  const previousRoom = sessionStorage.getItem("currentSession");

  useEffect(() => {
    if (uri === "/room-credentials-error") {
      setBodyText("The credentials provided don't match an existing room, or the room is now inactive.");
    } else if (uri === "/room-inactive") {
      setBodyText("The room is not available.");
    } else if (uri === "/room-error") {
      setBodyText("Sorry, there was an error creating the room. Please try again later.");
    } else if (uri === "/no-rooms") {
      setBodyText("Sorry, there are no rooms available.");
    } else if (uri === "/left-room") {
      setBodyText("You have left the club.");
    } else {
      setBodyText("Your room has been already closed.");
    }
  }, []);

  return (
    <div className="expired-room-container">
      <Header />
      <h1 className="title-expired-room">{bodyText}</h1>
      {uri === "/left-room" && previousRoom ? (
        <a href={previousRoom} className="re-join-room-button">
          <div className="button-pill-home ">
            <p>Rejoin the club</p>
          </div>
        </a>
      ) : (
        <Link to="/">
          <div className="button-pill-home ">
            <p>Back to homepage</p>
          </div>
        </Link>
      )}
      <div className="telly-img-expired-room">
        <img src="https://www.brandlive.com/wp-content/uploads/telly-1536x1288.png" alt="telly" />
      </div>
      {/*
      {!joinRoom ? 
      <PageActions forwardAction='/select-character' callback={handleEnterInformationSubmit} backwardAction='/select-music' />:
      <PageActions forwardAction='/select-character' callback={handleEnterInformationSubmit} backwardAction='https://www.brandlive.com' /> */}
    </div>
  );
}

export default ExpiredRoom;
