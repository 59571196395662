import React from "react";

function Gradient({ children, width, height }) {
  return (
    <div
      className="gradient-border button-size"
      style={{ width: width, height: height }}
    >
      {children}
    </div>
  );
}

export default Gradient;
