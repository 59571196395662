import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ALL_GLOBAL_VARIABLES from "../global";
// eslint-disable-next-line no-undef
const aws4 = require("aws4");

function getRoomData(setLoadingRoom, setRoomData, navigate) {
  const key = "cFhPfmSTqOufIstjup2KB1SXSRq3Nryg1WEUbptY";
  const accessKey = "AKIA344DB35P2E2TBHPF";

  const opts = {
    host: "api.tellyclub.com",
    path: "/getroom",
    service: "execute-api",
    region: "us-west-2",
  };

  aws4.sign(opts, { accessKeyId: accessKey, secretAccessKey: key });

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: opts.headers.Authorization,
      "X-Amz-Date": opts.headers["X-Amz-Date"],
    },
  };

  axios
    .get("https://api.tellyclub.com/getroom", config)
    .then((res) => {
      setLoadingRoom(false);
      setRoomData(res.data);
      localStorage.setItem("room-data", JSON.stringify(res.data));
    })
    .catch((error) => {
      console.log(error);
      navigate("/no-rooms");
    });
}

function CreateServer({ setRoomData, roomData }) {
  const navigate = useNavigate();

  const [loadingRoom, setLoadingRoom] = useState(true);
  // const [roomData, setRoomData] = useState({});
  // const [roomData, setRoomData] = useNewRoom();

  // Add the ability to disable the api and go straight to the next page.
  useEffect(() => {
    if (ALL_GLOBAL_VARIABLES.useAPI) {
      getRoomData(setLoadingRoom, setRoomData, navigate);
    } else {
      navigate("/select-room");
    }
  }, []);

  // gwrO1tp!@trY

  useEffect(() => {
    if (!loadingRoom && roomData) {
      setTimeout(() => {
        navigate("/select-room");
      }, 3000);
    }
  }, [loadingRoom, roomData]);

  return (
    <div className="create-room-wrapper">˜
      <div className="loader">
        <span className="dot dot-1" >T</span>
        <span className="dot dot-2" >E</span>
        <span className="dot dot-3" >L</span>
        <span className="dot dot-4" >L</span>
        <span className="dot dot-5" >Y</span>
      </div>
    </div>
  );
}

export default CreateServer;
