import React from 'react';

function HomeCard(props) {

  return (
    <>
      <div className="home-card-wrapper">
        <div className="home-card">
          <div className="home-card-left">
            <img src={props.cardImage} alt="" />
          </div>
          <div className="home-card-right">
            <h2 className="home-card-title">{props.cardTitle}</h2>
            {/* <div className="home-card-cta"><LargePillButton actionDestination="/select-room" icon='https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/100768/documents/645213fc-2b9c-4711-a415-ca349f3e97f5/right-arrow-with-tail.png' text="Create room" width="256" height="" ></LargePillButton></div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default HomeCard;