import React, { useState } from "react";

// Router
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Pages
import Page from "./Components/Page";
import MainPage from "./Pages/MainPage";
import SelectRoom from "./Pages/SelectRoom";
import SelectMusic from "./Pages/SelectMusic";
import EnterInformation from "./Pages/EnterInformation";
import SelectCharacter from "./Pages/SelectCharacter";
import ExpiredRoom from "./Pages/ExpiredRoom";
import CreateServer from "./Pages/CreateServer";
import CreateRoomNowOrLater from "./Pages/CreateRoomNowOrLater";
import CreateRoomLaterInfo from "./Pages/CreateRoomLaterInfo";
import AdminRoom from "./Pages/AdminRoom";
import UserRoom from "./Pages/UserRoom";
// Styles
import "./App.scss";

function App() {
  const [, setSelectedRoom] = useState();
  const [, setSelectedMusic] = useState();
  const [, setEnteredInformation] = useState();
  const [, setSelectedCharacter] = useState();
  const [roomData, setRoomData] = useState();
  const [userAdminEmail, setUserAdminEmail] = useState();
  const [roomId, setRoomId] = useState();

  const vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  return (
    <div className="App">
      <Router>
        <Routes>

          <Route exact path="/" element={<Page title="Telly Club"> <MainPage /> </Page>} />
          <Route exact path="/select-room" element={<Page title="Choose a Club"> <SelectRoom setSelectedRoom={setSelectedRoom} roomData={roomData} /> </Page>} />
          <Route exact path="/select-music" element={<Page title="Pick a Vibe"> <SelectMusic setSelectedMusic={setSelectedMusic} roomData={roomData} /> </Page>} />
          <Route exact path="/enter-information" element={<Page title="Add Your Info"> <EnterInformation setEnteredInformation={setEnteredInformation} roomData={roomData} userAdminEmail={userAdminEmail} roomId={roomId}/> </Page>} />
          <Route exact path="/select-character" element={<Page title="Select a Character"> <SelectCharacter setSelectedCharacter={setSelectedCharacter} roomData={roomData} /> </Page>} />
          <Route exact path="/join-room" element={<EnterInformation setEnteredInformation={setEnteredInformation} roomData={roomData} />} />
          <Route exact path="/create-room" element={<CreateServer setRoomData={setRoomData} roomData={roomData} />} />
          <Route exact path="/expired-room" element={<ExpiredRoom />} />
          <Route exact path="/room-credentials-error" element={<ExpiredRoom />} />
          <Route exact path="/room-inactive" element={<ExpiredRoom />} />
          <Route exact path="/room-error" element={<ExpiredRoom />} />
          <Route exact path="/no-rooms" element={<ExpiredRoom />} />
          <Route exact path="/left-room" element={<ExpiredRoom />} />
          <Route exact path="/create-room-now-or-later" element={<CreateRoomNowOrLater/>} />
          <Route exact path="/create-room-later-info" element={<CreateRoomLaterInfo/>} />
          <Route exact path="/reserved-room" element={<AdminRoom setUserAdminEmail={setUserAdminEmail} setRoomId={setRoomId} setRoomData={setRoomData}/>} />
          <Route exact path="/user-room" element={<UserRoom roomData={roomData} />} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
