import React, { useState, useEffect } from "react";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { Navigation } from "swiper";
import "swiper/swiper-bundle.min.css";

import PageActions from "../Components/PageActions";
import CircleButton from "../Components/Buttons/CircleButton";
import Header from "../Components/Header";

import PageTitleContent from "../Components/PageTitleContent";
import { updateAPISelected, checkIfRoomExists } from "../utils/utils";
import ALL_GLOBAL_VARIABLES from "../global";
import { useNavigate } from "react-router-dom";

const leftArrow =
  "https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/100768/documents/5c6cb93a-c8fe-4563-978b-7467bea7df94/left-arrow.png";

const rightArrow =
  "https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/100768/documents/5b5b8ef0-fc5e-4400-850a-a26933762b9d/right-arrow.png";

function SelectRoom({ setSelectedRoom, roomData }) {
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [checkRoomData, setCheckRoomData] = useState();

  const [showRightArrow, setShowRightArrow] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(false);

  const images = [
    "https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/1d62f3ae-5ee8-4e1a-bd25-d6085be4afa6/heros.gif",
    // "https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/3506b9ca-cad2-487d-8cae-a10db3f04165/room2.png",
  ];
  // Using this useEffect because it will default to undefined unless it is explicitly set.
  // Do we want it to have that behavior or can we default to 0 and get rid of the useEffect?
  const navigate = useNavigate();

  useEffect(() => {
    if (ALL_GLOBAL_VARIABLES.useAPI) {
      if (!roomData) {
        navigate("/");
      } else {
        checkIfRoomExists(roomData, setLoading, setCheckRoomData);
      }
    }
  }, [roomData]);

  //  Checks the status of the return from the api
  if (checkRoomData && !["ready", "reserved"].includes(checkRoomData.state)) {
    console.log("Something went wrong");
    navigate("/room-error");
  }
  // else if ( checkRoomData && ["ready", "reservered"].includes(checkRoomData.state)) {
  //   // setServer(server);
  //   // setPort(port);
  //   // setJoinRoom(!joinRoom);
  // }

  useEffect(() => {
    if (ALL_GLOBAL_VARIABLES.useAPI) {
      if (!loading) {
        // const AUTH_TOKEN = "Bearer token";
        setSelectedRoom(currentSlideIndex);
        updateAPISelected(
          { environment: currentSlideIndex + 1 },
          roomData,
          "/settings/environment"
        );
      }
    } else {
      setSelectedRoom(currentSlideIndex);
    }
  }, [currentSlideIndex, setSelectedRoom, loading]);

  return (
    <>
      <Header />
      <PageTitleContent action={"SELECT YOUR SPACE"} />
      <div className="left-gradient"></div>

      <div className="room-carousel-container">
        <Swiper
          modules={[Navigation]}
          slidesPerView={1}
          spaceBetween={10}
          navigation={{
            // points to elements with these class names for navigation
            prevEl: ".left-arrow",
            nextEl: ".right-arrow",
          }}
          breakpoints={{
            1280: {
              spaceBetween: 60,
            },
          }}
          // Add the room index to state
          onSlideChange={(swiper) => setCurrentSlideIndex(swiper.activeIndex)}
          onReachBeginning={() => {
            setShowLeftArrow(false);
          }}
          onReachEnd={() => {
            setShowRightArrow(false);
          }}
          onFromEdge={() => {
            setShowLeftArrow(true);
            setShowRightArrow(true);
          }}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img src={image} alt="specific room" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Navigation Elements */}
      <div className="arrow-containers">
        <div className="left-arrow">
          {showLeftArrow ? <CircleButton icon={leftArrow} /> : ""}
        </div>
        <div className="right-arrow">
          {showRightArrow ? <CircleButton icon={rightArrow} /> : ""}
        </div>
      </div>

      <div className="right-gradient"></div>
      <PageActions forwardAction="/select-music" backwardAction="/" step={1} />
    </>
  );
}

export default SelectRoom;
