import React, { useContext, useState } from 'react';

const ActiveMainPageContext = React.createContext();
const ActiveMainPageUpdateContext = React.createContext();

const ActiveSelectRoomContext = React.createContext();
const ActiveSelectRoomUpdateContext = React.createContext();

const ActiveSelectMusicContext = React.createContext();
const ActiveSelectMusicUpdateContext = React.createContext();

const ActiveEnterInformationContext = React.createContext();
const ActiveEnterInformationUpdateContext = React.createContext();

const ActiveSelectCharacterContext = React.createContext();
const ActiveSelectCharacterUpdateContext = React.createContext();

export function useActiveMainPage() {
  return [useContext(ActiveMainPageContext), useContext(ActiveMainPageUpdateContext)];
}

export function useActiveSelectRoom() {
  return [useContext(ActiveSelectRoomContext), useContext(ActiveSelectRoomUpdateContext)];
}

export function useActiveSelectMusic() {
  return [useContext(ActiveSelectMusicContext), useContext(ActiveSelectMusicUpdateContext)];
}

export function useActiveEnterInformation() {
  return [useContext(ActiveEnterInformationContext), useContext(ActiveEnterInformationUpdateContext)];
}

export function useActiveSelectCharacter() {
  return [useContext(ActiveSelectCharacterContext), useContext(ActiveSelectCharacterUpdateContext)];
}

export function ActivePageProvider({ children }) {

  const [activeMainPage, setActiveMainPage] = useState(true);
  const [activeSelectRoom, setActiveSelectRoom] = useState(false);
  const [activeSelectMusic, setActiveSelectMusic] = useState(false);
  const [activeEnterInformation, setActiveEnterInformation] = useState(false);
  const [activeSelectCharacter, setActiveSelectCharacter] = useState(false);

  return (

    <ActiveMainPageContext.Provider value={ activeMainPage}>
      <ActiveMainPageUpdateContext.Provider value={ setActiveMainPage}>
        <ActiveSelectRoomContext.Provider value={ activeSelectRoom}>
          <ActiveSelectRoomUpdateContext.Provider value={ setActiveSelectRoom}>
            <ActiveSelectMusicContext.Provider value={activeSelectMusic }>
              <ActiveSelectMusicUpdateContext.Provider value={setActiveSelectMusic }>
                <ActiveEnterInformationContext.Provider value={activeEnterInformation } >
                  <ActiveEnterInformationUpdateContext.Provider value={ setActiveEnterInformation}>
                    <ActiveSelectCharacterContext.Provider value={activeSelectCharacter }>
                      <ActiveSelectCharacterUpdateContext.Provider value={ setActiveSelectCharacter}>
                        {children}
                      </ActiveSelectCharacterUpdateContext.Provider>
                    </ActiveSelectCharacterContext.Provider>
                  </ActiveEnterInformationUpdateContext.Provider>
                </ActiveEnterInformationContext.Provider>
              </ActiveSelectMusicUpdateContext.Provider>
            </ActiveSelectMusicContext.Provider>
          </ActiveSelectRoomUpdateContext.Provider>
        </ActiveSelectRoomContext.Provider>
      </ActiveMainPageUpdateContext.Provider>
    </ActiveMainPageContext.Provider>

  );
}