import React from 'react';
import { Link } from 'react-router-dom';
import Gradient from '../Gradient';

function LargePillButton({ actionDestination, icon, text, width, height, callback }) {

  return (
    <Link to={actionDestination}>
      <Gradient width={width} height={height}>
        <div className='button-pill--container' onClick={callback}>
          <p className='button-pill--text'>{text}</p>
          <img className='nav-image' src={icon} alt='button icon' />
        </div>
      </Gradient>
    </Link>
  );
}

export default LargePillButton;
