import React, { useState, useEffect } from "react";

// Router
import { useNavigate } from "react-router-dom";

// Libraries
import isEmail from "validator/lib/isEmail";

// Components
import Header from "../Components/Header";
import PageTitleContent from "../Components/PageTitleContent";

// Admin Route
function AdminRoom({ setUserAdminEmail, setRoomId, setRoomData }) {
  const [roomIdFromURL, setRoomIdFromURL] = useState();
  const [responseFromApi, setResponseFromApi] = useState({});
  const [displayMessage, setDisplayMessage] = useState("");
  const [blankEmailActive, setBlankEmailActive] = useState("");
  const [emailError, setEmailError] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [isValidUrl, setIsValidUrl] = useState();

  const navigator = useNavigate();
  // Step 1: Check to get the room ID in the URL as the first page is loaded initially.
  useEffect(() => {
    const search = window.location.search;
    const query = new URLSearchParams(search);
    setRoomIdFromURL(query.get("room-id"));
  }, []);

  // Step 2: After we get the room ID, we call the api to check whether the room is opened or not.
  // The response from the api is saved to the variable
  useEffect(() => {
    // Guard clause for room ID being undefined.
    // Make sure the url the user enter is the valid one.
    if (roomIdFromURL) {
      setIsValidUrl(true);
    } else {
      setIsValidUrl(false);
      return;
    }

    fetch("https://api.tellyclub.com/room/id/parse", {
      method: "POST",
      body: `{"room-id": "${roomIdFromURL}"}`,
    })
      .then((response) => response.json())
      .then((data) => {
        setResponseFromApi(data);
      });
  }, [roomIdFromURL]);

  // Step 3: We based on the response back from api to display corresponding message.
  useEffect(() => {
    if (responseFromApi.error) {
      setDisplayMessage("Room is not opened yet! If you are the host, enter the admin information to open the room");
    }
    //If the room is open, it return the json with port, url, and server. Picked the port to have a check should be sufficient. 
    //If the room is open, go to the /user-room route so the regular users can enter their Telly info
    else if(responseFromApi.port){
      navigator("/user-room");
      setRoomData(responseFromApi);
    }
  }, [responseFromApi]);

  const handleEmailChange = (e) => {
    e.preventDefault();
    setAdminEmail(e.target.value);
  };

  // Step 4: The room is not opened, let the user enter the email to check if they are the Admin
  const handleEnterInformationSubmit = () => {
    // If email field is blank, display the corresponding warning
    if (adminEmail === "") {
      setBlankEmailActive("blank-field-warning--active");
      setEmailError("Please enter your email");
      return;
    }

    // Validate the email address and display the message accordingly.
    if (adminEmail && isEmail(adminEmail)) {
      // Valid email -> Call API
      openRoomData();
      // Passed down from App as a prop, so we can use it later to automatically fill into the email input. 
      setUserAdminEmail(adminEmail);
    } else {
      // Value in email field, but not an email
      setBlankEmailActive("blank-field-warning--active");
      setEmailError("Please enter the valid email");
    }
  };

  // Step 5: API Call - Check if the room id in the url is matching with the room id that is returned from the api.
  // If matching, navigate to the normal route
  const openRoomData = () => {
    fetch("https://api.tellyclub.com/room/id/generate", {
      method: "POST",
      body: `{"mail": "${adminEmail}"}`,
    })
      .then((response) => response.json())
      .then((data) => {
        const roomIdFromEmail = data["room-id"];

        if (roomIdFromURL === roomIdFromEmail) {
          setRoomId(roomIdFromEmail);
          navigator("/create-room");
        } else {
          setDisplayMessage("The admin email is not valid. Try Again!");
          setAdminEmail("");
        }
      });
  };

  // Render the page
  return (
    <>
      <Header />
      {isValidUrl ? (
        <div className="admin-route-wrapper">
          <PageTitleContent action={displayMessage} />
          <form className="enter-information__form">
            <label htmlFor="email">Your admin email</label>
            <label className={`blank-field-warning ${blankEmailActive}`} htmlFor="email">
              {emailError}
            </label>
            <input className="enter-information__form__input" type="email" name="email" placeholder="Your admin email..." value={adminEmail} onChange={handleEmailChange} />
          </form>

          <div className="page-actions-container">
            <div className="button-pill gradient-border gradient-border-no-fill button-size" onClick={handleEnterInformationSubmit}>
              <p className="button-pill--text">Open</p>
            </div>
          </div>
        </div>
      ) : (
        <PageTitleContent action={"Invalid URL. Please contact the host or try another URL."} />
      )}
    </>
  );
}

export default AdminRoom;
