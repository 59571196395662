import React, { useState, useEffect } from "react";
import PageTitleContent from "../Components/PageTitleContent";
import PageActions from "../Components/PageActions";
import MusicBox from "../Components/MusicBox";

import Header from "../Components/Header";

import { updateAPISelected, checkIfRoomExists } from "../utils/utils";
import ALL_GLOBAL_VARIABLES from "../global";
import { useNavigate } from "react-router-dom";

function SelectMusic({ setSelectedMusic, roomData }) {
  const [activeCard, setActiveCard] = useState(0);
  const [loading, setLoading] = useState(true);
  const [checkRoomData, setCheckRoomData] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (ALL_GLOBAL_VARIABLES.useAPI) {
      if (!roomData) {
        navigate("/");
      } else {
        checkIfRoomExists(roomData, setLoading, setCheckRoomData);
      }
    }
  }, [roomData]);

  if (checkRoomData && !["ready", "reserved"].includes(checkRoomData.state)) {
    console.log("Something went wrong");
    navigate("/room-error");
  }

  const music = [
    {
      artwork:
        "https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/7/documents/ec8be599-2a67-43fb-a25e-6b92a3cb454f/Telly_Music_2021-12-20_lowres_ambient_Shadow.png",
      title: "Ambient",
      track: "https://brandlive-upload.s3-us-west-2.amazonaws.com/17/documents/1www2tvnsr/Ambient-trimmed.mp3"
    },
    {
      artwork:
        "https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/7/documents/51a50fc2-56fa-4fab-ba20-23de7366956f/Telly_Music_2021-12-20_lowres_lofi_Shadow.png",
      title: "Lo Fi",
      track: "https://brandlive-upload.s3-us-west-2.amazonaws.com/17/documents/e8bqpbo5ty/lofi-trimmed.mp3"
    },
    {
      artwork:
        "https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/7/documents/cff27b8b-b1c4-4b1d-a3f1-4cd292d91dcf/Telly_Music_2021-12-20_lowres_party_Shadow.png",
      title: "Party",
      track: "https://brandlive-upload.s3-us-west-2.amazonaws.com/17/documents/anubetd177/Party-trimmed.mp3"
    },
    {
      artwork:
        "https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/7/documents/5a3197fb-c458-41d7-bf64-27c298746cad/Telly_Music_2021-12-20_lowres_upbeat_Shadow.png",
      title: "Upbeat",
      track: "https://brandlive-upload.s3-us-west-2.amazonaws.com/17/documents/wtmaz4lfoe/Upbeat-trimmed.mp3"
    },
  ]; //Pretend this is the API return

  useEffect(() => {
    if (ALL_GLOBAL_VARIABLES.useAPI) {
      if (!loading) {
        setSelectedMusic(activeCard);
        updateAPISelected(
          { music: activeCard + 1 },
          roomData,
          "/settings/music"
        );
      }
    } else {
      setSelectedMusic(activeCard);
    }
  }, [activeCard, setSelectedMusic, roomData, loading]);

  return (
    <div>
      <Header />
      <PageTitleContent action={"CHOOSE YOUR VIBE"} />
      <div className="select-music-container">
        <div className="music-container-inner">
          {music.flatMap(({ artwork, title, track }, i) => (
            <MusicBox
              key={i}
              artwork={artwork}
              audio=""
              title={title}
              id={i}
              classes={`music-box-container ${i === activeCard ? "image-gradient-border" : ""}`}
              setActiveCard={setActiveCard}
              track={track}
            />
          ))}
        </div>
      </div>
      <PageActions
        forwardAction="/enter-information"
        backwardAction="/select-room"
        step={2}
      />
    </div>
  );
}

export default SelectMusic;
