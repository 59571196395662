import React from 'react';

function PageTitleContent({ action }) {
  return (
    <div className='page-content-title'>
      <div className='page-title'>{action}</div>
    </div>
  );
}

export default PageTitleContent;
