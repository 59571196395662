import React from "react";

import { useNavigate } from "react-router-dom";

import PageTitleContent from "../Components/PageTitleContent";
import Header from "../Components/Header";

const CreateRoomNowOrLater = () => {
  const navigator = useNavigate();

  const handleBackButton = () => {
    navigator("/");
  };

  const moveToNowRoute = (e) => {
    e.preventDefault();
    navigator("/create-room");
  };

  const moveToLaterRoute = (e) => {
    e.preventDefault();
    navigator("/create-room-later-info");
  };

  return (
    <div className="wrapper-room-now-later">
      <Header />

      <div className="create-room-now-or-later">
        <PageTitleContent action={"CREATE ROOM NOW OR LATER"} />

        <div className="option-buttons">
          <button className="button-size button-now" onClick={moveToNowRoute}>
            Now
          </button>
          <button className="button-size button-later" onClick={moveToLaterRoute}>
            Later
          </button>
        </div>

        <div className="page-actions-container">
          <div className="button-pill gradient-border gradient-border-no-fill button-size" onClick={handleBackButton}>
            <p className="button-pill--text">Back</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateRoomNowOrLater;
