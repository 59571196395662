import React from 'react';

function CircleButton({ icon }) {
  return (
    <div className="button-circle ">
      <img className="nav-image--white" src={icon} alt="button icon" />
    </div>
  );
}

export default CircleButton;
