import React, { useState, useEffect } from "react";
import ALL_GLOBAL_VARIABLES from "../global";

// Libraries
import axios from "axios";
import isEmail from "validator/lib/isEmail";

// Router
import { useNavigate } from "react-router-dom";
import Header from "../Components/Header";

// Utility
import { updateAPISelected, checkIfRoomExists, checkRoomUsersLimit, incrementLobbyUser, updateRoomID  } from "../utils/utils";


// Components
import PageTitleContent from "../Components/PageTitleContent";


// Config file for the get request
// Will need to swap out the master bearer token eventually
//  Turning off because it is unused for now
// const config = {
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: "Bearer gwrO1tp!@trY",
//   },
// };

async function addUserDataToBrandliveAPI(bl_name, bl_email, bl_userToken, bl_server, bl_port) {
  const payload = {
    email: bl_email,
    username: bl_name,
    url: "https://tellyclub.com",
    server: bl_server,
    port: bl_port,
    token: bl_userToken,
  };

  try {
    const config = {
      method: "post",
      url: "https://api-hv.brandlive-dev.com/telly-club-generate-session",
      data: payload,
    };

    const room = await axios(config)
      .then(() => {})
      .catch((e) => {
        console.log(`e`, e);
      });
    return room.data;
  } catch (error) {
    return null;
  }
}

function EnterInformation({ setEnteredInformation, roomData, userAdminEmail, roomId }) {
  // States that will be filled be the user
  const [blankNameActive, setBlankNameActive] = useState("");
  const [blankEmailActive, setBlankEmailActive] = useState("");
  const [usedEmail, setUsedEmail] = useState("");

  // States passed in the url
  const [, setServer] = useState();
  const [, setPort] = useState();

  const [rd, setRD] = useState();
  const [userToken, setUserToken] = useState();

  const [loading, setLoading] = useState(true);
  const [checkRoomData, setCheckRoomData] = useState();
  const [isJoinRoom, setIsJoinRoom] = useState(false);
  const [isFromAdminRoute, setIsFromAdminRoute] = useState(false);
  const [emailError, setEmailError] = useState("");

  // const userNameRef = useRef("");
  // const userEmailRef = useRef("");
  // States that will be filled by the user
  const [values, setValues] = useState({
    name: "",
    email: "",
  });

  // Users limit for the room
  const [currentUsersNum, setCurrentUsersNum] = useState();
  const [inLobby, setInLobby] = useState(false);
  const navigate = useNavigate();

  // --- Setting user information --- //
  const handleNameChange = (e) => {
    setValues((values) => ({
      ...values,
      name: e.target.value,
    }));
  };

  const handleEmailChange = (e) => {
    setValues((values) => ({
      ...values,
      email: e.target.value,
    }));
  };

  // Check if the previous route is from the admin route initially. If it is, we will have their emaill passed down from AdminRoom page.
  useEffect(() => {
    if (userAdminEmail) {
      setIsFromAdminRoute(true);
      values.email = userAdminEmail;
    }
  }, []);

  // Get the server and port data from the url if it exists
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const urlLink = window.location.pathname;

    if (ALL_GLOBAL_VARIABLES.useAPI) {
      if (urlLink === "/join-room") {
        // Get query string parameters
        const serverTemp = params.get("server");
        const portTemp = params.get("port");

        // If query string parameters don't exist, go to broken room
        if (!serverTemp || !portTemp) {
          navigate("/expired-room");
        }

        // if we're not in join room, move on
        setServer(serverTemp);
        setPort(portTemp);
        setIsJoinRoom(true);
        // setIsJoinRoom(!isJoinRoom);

        const rdData = {
          "admin-token": "gwrO1tp!@trY",
          port: portTemp,
          server: serverTemp,
          url: "https://app.tellyclub.com",
        };

        setRD(rdData);
        checkIfRoomExists(rdData, setLoading, setCheckRoomData);

        checkRoomUsersLimit(rdData, setCurrentUsersNum);

        return;
      }

      // if we're not in join room, move on
      else if (!roomData) {
        navigate("/");
      } else {
        checkIfRoomExists(roomData, setLoading, setCheckRoomData);
      }
    }
  }, [roomData]);

  useEffect(() => {
    if(currentUsersNum >= 12) {
      navigate("/room-error");
    }
  }, [currentUsersNum]);

  // This is the check if the server exists and if it doesn't redirect
  // to the expired page
  useEffect(() => {
    if (!loading && checkRoomData) {
      //  Checks the status of the return from the api

      if (checkRoomData && !["ready", "reserved", "in-use"].includes(checkRoomData.state)) {
        console.log("Something went wrong");
        navigate("/room-error");
      }
    }
  }, [loading, checkRoomData]);

  function setLocalStorage(value) {
    window.sessionStorage.setItem("currentSession", value);
  }

  function handleEnterInformationSubmit() {
   
    // If the name or email field is blank, display the corresponding warning
    if (values.name === "") {
      setBlankNameActive("blank-field-warning--active");
      setEmailError("Please enter your email");
    } else {
      setBlankNameActive("");
    }

    if (values.email === "") {
      setBlankEmailActive("blank-field-warning--active");
    } else {
      setBlankEmailActive("");
    }

    // cancel the data handling and going to the next page
    if (values.name === "" || values.emails === "") {
      return;
    }

    // Validate the email address and display the message accordingly.
    if (!isEmail(values.email)) {
      setBlankEmailActive("blank-field-warning--active");
      setEmailError("Please enter the valid email");
      return;
    }

    const informationID = `${values.name}-|||-${values.email}`;
    setEnteredInformation(informationID);

    if (!loading) {
      setEnteredInformation(informationID);
      if (ALL_GLOBAL_VARIABLES.useAPI) {
        if (isJoinRoom) {
          updateAPISelected({ id: informationID, name: values.name, mail: values.email }, rd, "/users/add", setUserToken, setUsedEmail);
          incrementLobbyUser({ id: informationID, name: values.name, mail: values.email }, rd);
        } else {
          updateAPISelected({ id: informationID, name: values.name, mail: values.email }, roomData, "/users/add", setUserToken);
          incrementLobbyUser({ id: informationID, name: values.name, mail: values.email }, roomData);
        }
        //This is from the admin route - create room later route.
        //RoomId is the id that is  generated based on the admin email from the api end point that is passed down from App.
        if(isFromAdminRoute && roomId){
          // Set up the admin token for admin user and updated the room ID to get the room with the new data from URL.
          updateRoomID(roomData, roomId, "/roomid", setUserToken);
        }
      }
    }

   

    // Collect user data and send to the next step or the back end
    //
    // admin-token: "eyJhbGciOiJIUzI1NiJ9.YWRtaW4.fPPCS9fU12w24KiF_ZpSYqZp_0ChM45-TcxCBCZrZAU"
    // port: "1939"
    // server: "spacewalk.tellyclub.com"
    // url: "https://tellyclub.com"
    // Create URL from the room object:
    // informationID
    //${roomData['admin-token']}`
    //${informationID}`
    // navigate("/select-character");
  }

  const handleBackButton = () => {
    navigate("/select-music");
  };

  useEffect(() => {
    if(usedEmail !== "") {
      setEmailError("Sorry, this email is already in use");
    }
  }, [usedEmail])

  useEffect(() => {
    if (userToken && !isJoinRoom) {
      addUserDataToBrandliveAPI(values.name, values.email, userToken, roomData.server, roomData.port);
      // const url = `${roomData.url}?server=${roomData.server}&port=${roomData.port}&token=${userToken}`;
      const url = `https://app.tellyclub.com?server=${roomData.server}&port=${roomData.port}&token=${userToken}`;
      setLocalStorage(`https://app.tellyclub.com?server=${roomData.server}&port=${roomData.port}`);
      window.location.replace(url);
    } else if (userToken && isJoinRoom) {
      addUserDataToBrandliveAPI(values.name, values.email, userToken, rd.server, rd.port);
      // const url = `${rd.url}?server=${rd.server}&port=${rd.port}&token=${userToken}`;
      const url = `https://app.tellyclub.com?server=${rd.server}&port=${rd.port}&token=${userToken}`;
      setLocalStorage(`https://app.tellyclub.com?server=${rd.server}&port=${rd.port}`);
      window.location.replace(url);
    }
  }, [userToken]);
  return (
    <>
      <Header />
      <div className="enter-information">
        {!isJoinRoom ? <PageTitleContent action={"NAME YOUR TELLY"} /> : <PageTitleContent action={"NAME YOUR TELLY"} />}

        <form className="enter-information__form">
          <label htmlFor="name">Your name</label>
          <label className={`blank-field-warning ${blankNameActive}`} htmlFor="name">
            Please enter your name
          </label>
          <input className="enter-information__form__input" type="text" name="name" placeholder="Type your name here..." onChange={handleNameChange} value={values.name}></input>
          {userAdminEmail ? <label htmlFor="email">Your admin email</label> : <label htmlFor="email">Your email</label>}
          <label className={`blank-field-warning ${blankEmailActive} ${usedEmail}`} htmlFor="email">
            {emailError}
          </label>
          <input className="enter-information__form__input" type="text" name="email" placeholder="And your email..." value={values.email} onChange={handleEmailChange}></input>
        </form>
      </div>
      {!isJoinRoom ? (
        <div className="page-actions-container">
          <div className="page-actions">
            <div onClick={handleBackButton}>
              <div className="button-pill gradient-border gradient-border-no-fill button-size">
                <p className="button-pill--text">Back</p>
              </div>
            </div>
            <div className="step-container">
              <div className="step-number"> 3&nbsp;</div>
              <div className="step-total">of 4</div>
            </div>

            <div className="gradient-border  next-container button-size-next button-size" onClick={handleEnterInformationSubmit}>
              <div className="button-pill--container">
                <p className="button-pill--text">Next</p>
                <img className="nav-image" src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/100768/documents/645213fc-2b9c-4711-a415-ca349f3e97f5/right-arrow-with-tail.png" alt="button icon" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="page-actions-container">
          <div className="page-actions">
            <div className="step-container">
              <div className="step-number"> 1&nbsp;</div>
              <div className="step-total">of 2</div>
            </div>
            <div className="gradient-border  next-container button-size-next button-size" onClick={handleEnterInformationSubmit}>
              <div className="button-pill--container">
                <p className="button-pill--text">Next</p>
                <img className="nav-image" src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/100768/documents/645213fc-2b9c-4711-a415-ca349f3e97f5/right-arrow-with-tail.png" alt="button icon" />
              </div>
            </div>
          </div>
        </div>
      )}

      {/* {!isJoinRoom ? (
        <PageActions
          //forwardAction="/select-character"
          callback={handleEnterInformationSubmit}
          backwardAction="/select-music"
          step={3}
          totalsteps={4}
        />
      ) : (
        <PageActions
          hiddenDisplay="none"
          //forwardAction="/select-character"
          callback={handleEnterInformationSubmit}
          backwardAction="disabled"
          step={1}
          totalsteps={2}
        />
      )} */}
    </>
  );
}

export default EnterInformation;
