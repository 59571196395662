import React from 'react';
import MusicPlayer from '../Components/MusicPlayer';
// eslint-disable-next-line no-undef

function MusicBox(props) {
  const musicTrack = props.track;
  const handleSelection = () => {
    props.setActiveCard(props.id);
  };

  return (
    <>
      <div className={props.classes} id={props.id} onClick={handleSelection}>
        <div className='music-box-inner'>
          <div className='artwork-wrapper'>
            <img src={props.artwork} alt='music' />
          </div>
          <MusicPlayer audio={musicTrack} index={props.id} title={props.title} ></MusicPlayer>
        </div>
      </div>
    </>
  );
}

export default MusicBox;
