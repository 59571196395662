import React, { useState, useEffect } from "react";

// Libraries
import isEmail from "validator/lib/isEmail";
import { useNavigate } from "react-router-dom";

// Components
import Header from "../Components/Header";
import PageTitleContent from "../Components/PageTitleContent";

// Now or later page
function CreateRoomLaterInfo() {
  const [blankNameActive, setBlankNameActive] = useState("");
  const [blankEmailActive, setBlankEmailActive] = useState("");
  const [emailError, setEmailError] = useState("");
  const [roomID, setRoomID] = useState("");
  const [urlWithRoomId, setUrl] = useState("");
  const [isCopied, setCopied] = useState(false);
  const [copyText, setCopyText] = useState("");

  // States that will be filled by the user
  const [values, setValues] = useState({
    name: "",
    email: "",
  });

  const navigator = useNavigate();

  // API call
  const reserveRoomData = () => {
    fetch("https://api.tellyclub.com/room/id/generate", {
      method: "POST",
      body: `{"mail": "${values.email}"}`,
    })
      .then((response) => response.json())
      .then((data) => {
        setRoomID(data["room-id"]);
      });
  };
  // Navigates the user back to the now or later page
  const handleBackButton = () => {
    navigator("/create-room-now-or-later");
  };

  // --- Setting user information --- //
  const handleNameChange = (e) => {
    e.preventDefault();
    setValues((values) => ({
      ...values,
      name: e.target.value,
    }));
  };
  const handleEmailChange = (e) => {
    e.preventDefault();
    setValues((values) => ({
      ...values,
      email: e.target.value,
    }));
  };
  // --- //

  // Navigate user back to landing page when they click on the Done button
  const handleDone = () => {
    navigator("/");
  };

  // When user submits their name and email, repaints the page
  const handleEnterInformationSubmit = () => {
    // If the name or email field is blank, display the corresponding warning
    if (values.name === "") {
      setBlankNameActive("blank-field-warning--active");
    } else setBlankNameActive("");

    if (values.email === "") {
      setBlankEmailActive("blank-field-warning--active");
      setEmailError("Please enter your email");
    } else setBlankEmailActive("");

    // Validate the email address and display the message accordingly.
    if (values.email !== "") {
      if (!isEmail(values.email)) {
        setBlankEmailActive("blank-field-warning--active");
        setEmailError("Please enter the valid email");
      }
    }

    // Cancel the data handling and going to the next page
    if (values.name === "" || values.emails === "") {
      return;
    }

    //Call the API
    if (values.email && values.name && isEmail(values.email)) reserveRoomData();
  };

  // Copy URL with room ID to user's clipboard
  function copyUrl() {
    window.navigator.clipboard.writeText(urlWithRoomId);

    // Changing the state of the copy button
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  }

  // Changing the text of the copy button based on its state
  useEffect(() => {
    if (isCopied) {
      setCopyText("Copied!");
    } else {
      setCopyText("Copy URL");
    }
  }, [isCopied]);

  // Set Room ID
  useEffect(() => {
    const generatedLink = `https://www.tellyclub.com/reserved-room?room-id=${roomID}`;
    setUrl(generatedLink);
  }, [roomID]);

  // Render the page
  return (
    <>
      <Header />
      {/* Display the form when room ID string is not valid or repaint to display room ID URL*/}
      {!roomID ? (
        <div className="enter-information">
          <PageTitleContent action={"RESERVE THE ROOM"} />
          <form className="enter-information__form">
            <label htmlFor="name">Your name</label>
            <label className={`blank-field-warning ${blankNameActive}`} htmlFor="name">
              Please enter your name
            </label>
            <input className="enter-information__form__input" type="text" name="name" placeholder="Type your name here..." onChange={handleNameChange} value={values.name} />

            <label htmlFor="email">Your email</label>
            <label className={`blank-field-warning ${blankEmailActive}`} htmlFor="email">
              {emailError}
            </label>
            <input className="enter-information__form__input" type="email" name="email" placeholder="And your email..." value={values.email} onChange={handleEmailChange} />
          </form>
        </div>
      ) : (
        <div className="enter-information">
          <PageTitleContent action={"Here is your generated room ID"} />
          <div className="room-info">
            <h4 className="room-url">
              <span>URL: </span> {urlWithRoomId}
            </h4>
            <input className="copy-text" type="text" value={urlWithRoomId} readOnly />
            <div className="button-copy-wrapper">
              <div className="button-copy button-pill button-size" onClick={copyUrl}>
                <p className="button-pill--text">{copyText}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="page-actions-container">
        <div className="page-actions">
          <div onClick={handleBackButton}>
            <div className="button-pill gradient-border gradient-border-no-fill button-size">
              <p className="button-pill--text">Back</p>
            </div>
          </div>

          {!roomID ? (
            <div className="enter-information">
              <div className="gradient-border next-container button-size-next button-size" onClick={handleEnterInformationSubmit}>
                <div className="button-pill--container">
                  <p className="button-pill--text">Reserve</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="enter-information">
              <div className="gradient-border next-container button-size-next button-size" onClick={handleDone}>
                <div className="button-pill--container">
                  <p className="button-pill--text">Done</p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default CreateRoomLaterInfo;
