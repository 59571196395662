import { useEffect } from "react";

// Wraps page components
const Page = ({ title, children }) => {
  useEffect(() => {
    document.title = title || "";
  }, [title]);
  return children;
};

export default Page;