import React from "react";

function SelectCharacter() {
  return (
    <div>
      <h1>SELECT CHARACTER</h1>
    </div>
  );
}

export default SelectCharacter;
