import React, { useState, useEffect } from "react";
import ALL_GLOBAL_VARIABLES from "../global";

// Libraries
import axios from "axios";
import isEmail from "validator/lib/isEmail";

// Router
import { useNavigate } from "react-router-dom";

// Utility
import { updateAPISelected } from "../utils/utils";

// Components
import PageTitleContent from "../Components/PageTitleContent";
import Header from "../Components/Header";

async function addUserDataToBrandliveAPI(bl_name, bl_email, bl_userToken, bl_server, bl_port) {
  const payload = {
    email: bl_email,
    username: bl_name,
    url: "https://tellyclub.com",
    server: bl_server,
    port: bl_port,
    token: bl_userToken,
  };

  try {
    const config = {
      method: "post",
      url: "https://api-hv.brandlive-dev.com/telly-club-generate-session",
      data: payload,
    };

    const room = await axios(config)
      .then(() => {})
      .catch((e) => {
        console.log(`e`, e);
      });
    return room.data;
  } catch (error) {
    return null;
  }
}

function UserRoom({ roomData }) {
  // States that will be filled be the user
  const [blankNameActive, setBlankNameActive] = useState("");
  const [blankEmailActive, setBlankEmailActive] = useState("");

  // States passed in the url
  const [rd, setRD] = useState();
  const [userToken, setUserToken] = useState();

  //States for the form
  const [emailError, setEmailError] = useState("");
  const [values, setValues] = useState({
    name: "",
    email: "",
  });

  // Use this config to add the users to API end point and make sure add them
  // into the same room as the admin using the same port and same server that is passed down from the App to the Admin route. 
  useEffect(() => {
    const rdData = {
      "admin-token": "gwrO1tp!@trY",
      port: roomData.port,
      server: roomData.server,
      url: "https://app.tellyclub.com",
    };
    setRD(rdData);
  }, []);

  const navigate = useNavigate();

  // --- Setting user information --- //
  const handleNameChange = (e) => {
    setValues((values) => ({
      ...values,
      name: e.target.value,
    }));
  };

  const handleEmailChange = (e) => {
    setValues((values) => ({
      ...values,
      email: e.target.value,
    }));
  };

  const handleBackButton = () => {
    navigate("/");
  };

  function handleEnterInformationSubmit() {
    // If the name or email field is blank, display the corresponding warning
    if (values.name === "") {
      setBlankNameActive("blank-field-warning--active");
      setEmailError("Please enter your email");
    } else {
      setBlankNameActive("");
    }

    if (values.email === "") {
      setBlankEmailActive("blank-field-warning--active");
    } else {
      setBlankEmailActive("");
    }

    // cancel the data handling and going to the next page
    if (values.name === "" || values.emails === "") {
      return;
    }

    // Validate the email address and display the message accordingly.
    if (!isEmail(values.email)) {
      setBlankEmailActive("blank-field-warning--active");
      setEmailError("Please enter the valid email");
      return;
    }

    //Add the user to the API to get the user token
    const informationID = `${values.name}-|||-${values.email}`;
    if (ALL_GLOBAL_VARIABLES.useAPI) {
      updateAPISelected({ id: informationID, name: values.name, mail: values.email }, rd, "/users/add", setUserToken);
    }
  }

  function setLocalStorage(value) {
    window.sessionStorage.setItem("currentSession", value);
  }

  useEffect(() => {
    //When we have the user token, add the user into the same room as the admin using the port and server that is passed down from Admin Route.
    if (userToken) {
      addUserDataToBrandliveAPI(values.name, values.email, userToken, roomData.server, roomData.port);
      const url = `https://app.tellyclub.com?server=${rd.server}&port=${rd.port}&token=${userToken}`;
      setLocalStorage(`https://app.tellyclub.com?server=${roomData.server}&port=${roomData.port}`);
      window.location.replace(url);
    }
  }, [userToken]);
  return (
    <>
      <Header />
      <div className="enter-information">
        <PageTitleContent action={"NAME YOUR USER TELLY"} />

        <form className="enter-information__form">
          <label htmlFor="name">Your name</label>
          <label className={`blank-field-warning ${blankNameActive}`} htmlFor="name">
            Please enter your name
          </label>
          <input className="enter-information__form__input" type="text" name="name" placeholder="Type your name here..." onChange={handleNameChange} value={values.name}></input>
          <label htmlFor="email">Your email</label>
          <label className={`blank-field-warning ${blankEmailActive}`} htmlFor="email">
            {emailError}
          </label>
          <input className="enter-information__form__input" type="text" name="email" placeholder="And your email..." value={values.email} onChange={handleEmailChange}></input>
        </form>
      </div>
      <div className="page-actions-container">
        <div className="page-actions">
          <div onClick={handleBackButton}>
            <div className="button-pill gradient-border gradient-border-no-fill button-size">
              <p className="button-pill--text">Back</p>
            </div>
          </div>
          <div className="gradient-border  next-container button-size-next button-size" onClick={handleEnterInformationSubmit}>
            <div className="button-pill--container">
              <p className="button-pill--text">Next</p>
              <img className="nav-image" src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/100768/documents/645213fc-2b9c-4711-a415-ca349f3e97f5/right-arrow-with-tail.png" alt="button icon" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UserRoom;
