import React, { useState, useEffect, useRef } from "react";
import useDetectOutsideClicks from "../hooks/useDetectOutsideClicks";

const AudioPlayer = ({ audio, index, title }) => {
  //const [trackProgress, setTrackProgress] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [showTracker, setShowTracker] = useState(false);
  const [, setPercentComplete] = useState(0);
  const audioRef = useRef(`#playbutton-${index}`);
  const duration = audioRef.current.duration;

  const wrapperRef = useRef(null);
  useDetectOutsideClicks(wrapperRef, setIsPlaying);

  let playInterval;

  const pauseAllPlayers = () => {
    const allPlayButtons = document.querySelectorAll(".playbutton");
    allPlayButtons.forEach((playbutton) => {
      // check if audio is playing
      // then click pause
      playbutton.pause();
    });
  };

  const playPauseClick = () => {
    if (!isPlaying) {
      pauseAllPlayers();
    }
    setShowTracker(true);
    setIsPlaying(!isPlaying);
  };

  const startTimer = () => {
    playInterval = setInterval(() => {
      const progressTracker = document.getElementById(
        `progress-inner-${index}`
      );
      if (audioRef.current) {
        const percent = (audioRef.current.currentTime / duration) * 100;
        progressTracker.style.width = percent + "%";

        setPercentComplete(percent);
      }
    }, 100);
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      clearInterval(playInterval);
      audioRef.current.pause();
    }
  }, [isPlaying]);

  return (
    <div className="music-player-container" ref={wrapperRef}>
      {showTracker ? (
        <div id={`audio-progress-${index}`} className="audio-progress">
          <div className="progress-inner" id={`progress-inner-${index}`}></div>
        </div>
      ) : (
        <div className="tracker-empty"></div>
      )}
      <div className="player">
        <div className="playlist-title">{title}</div>
        <div className="play-button" onClick={playPauseClick}>
          <audio
            className="playbutton"
            id={`playbutton-${index}`}
            ref={audioRef}
            src={audio}
          />
          {!isPlaying ? (
            <img
              src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/79db90f9-2383-4f7e-82e1-defce8e9f83c/play.png"
              alt="play"
            />
          ) : (
            <img
              src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/20d61d6c-f223-45ac-9692-2e3bfedc2bfa/pause-button.png"
              alt="pause"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
