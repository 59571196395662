import React from "react";
import { Link } from "react-router-dom";

function SmallPillButton({ isDisplay, text, actionDestination }) {
  return (
    <Link to={actionDestination}>
      <div
        style={{ display: `${isDisplay}` }}
        className="button-pill gradient-border gradient-border-no-fill button-size"
      >
        <p className="button-pill--text">{text}</p>
      </div>
    </Link>
  );
}

export default SmallPillButton;
