import { React } from "react";
// Wraps page components
const Header = () => {
  return (
    <div>
      <header className="App-header">
        <img
          src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/fe410305-c715-4071-a3f1-525c014e6468/Telly-Club-WHITE.png"
          alt="telly club header"
        />
      </header>
    </div>
  );
};

export default Header;
