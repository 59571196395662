import React from "react";
import SmallPillButton from "../Components/Buttons/SmallPillButton";
import LargePillButton from "../Components/Buttons/LargePillButton";

function PageActions({
  hiddenDisplay,
  forwardAction,
  callback,
  backwardAction,
  step,
  totalsteps = 4,
}) {
  // This makes the callback function prop optional, and prevents any other non-function types getting passed through and breaking the app
  if (typeof callback !== "function") {
    callback = null;
  }

  return (
    <div className="page-actions-container">
      <div className="page-actions">
        {/* Cancel Button */}
        {backwardAction !== "disabled" ? (
          <SmallPillButton
            isDisplay={hiddenDisplay}
            text={"Back"}
            actionDestination={backwardAction || "/"}
          />
        ) : (
          ""
        )}
        <div className="step-container">
          <div className="step-number"> {step} </div>
          <div className="step-total">of {totalsteps}</div>
        </div>
        {/* Next Button */}
        <LargePillButton
          icon={
            "https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/100768/documents/645213fc-2b9c-4711-a415-ca349f3e97f5/right-arrow-with-tail.png"
          }
          text={"Next"}
          actionDestination={forwardAction || "/"}
          callback={callback}
        />
      </div>
    </div>
  );
}

export default PageActions;
