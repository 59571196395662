import { useEffect } from "react";

const useDetectOutsideClicks = (ref, setIsPlaying) => {
  useEffect(() => {
    // Listen for clicks
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsPlaying(false);
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, setIsPlaying]);
};

export default useDetectOutsideClicks;
