import axios from 'axios';

//Generic Update function for each of the pages

export async function updateAPISelected(requestBody, roomData, endpoint, setUserToken, setUsedEmail) {
  try {
    const URL = `${roomData.server}`;
    const PORT = roomData.port;
    const ADMIN_API = "/admin-api/v1";
    const url = `${roomData.server.includes("https://") ? "" : "https://"}${URL}:${PORT}${ADMIN_API}${endpoint}`;
    const config = {
      method: 'post',
      url: url,
      headers: {
        "Authorization": `Bearer ${roomData["admin-token"]}`,//gwrO1tp!@trY,
        "Content-Type": "application/json",
      },
      data: requestBody
    };

    const room = await axios(config).then((response) => {
      if (setUserToken) {
        // Sends the token to the callback
        setUserToken(response?.data?.user?.token);
        if(setUsedEmail) {
          setUsedEmail("")
        }
      }
      if(response.data.error) {
        console.log('error', response.data)
        setUsedEmail("blank-field-warning--active")
      }

    }).catch(e => { console.log(`e`, e); });
    return room.data;
  } catch (error) {
    return null;
  }
}
// Set up the admin token for admin user and updated the room ID to get the room with the new data from URL.
export async function updateRoomID(roomData, roomId, endpoint, setUserToken) {
  try {
    const URL = `${roomData.server}`;
    const PORT = roomData.port;
    const ADMIN_API = "/admin-api/v1";
    const url = `${roomData.server.includes("https://") ? "" : "https://"}${URL}:${PORT}${ADMIN_API}${endpoint}`;

    const config = {
      method: 'post',
      url: url,
      headers: {
        "Authorization": `Bearer ${roomData["admin-token"]}`,
        "Content-Type": "application/json",
      },
      data: { "id": roomId }
    };

    await axios(config).then((response) => {
      if (setUserToken) {
        // Sends the token to the callback
        setUserToken(response?.data?.user?.token);
      }
    }).catch(e => { console.log(`e`, e); });
  } catch (error) {
    return null;
  }
}

export function checkIfRoomExists(roomData, setLoading, setCheckRoomData) {
  const URL = `${roomData.server.includes("https://") ? "" : "https://"}${roomData.server}`;
  const PORT = roomData.port;
  const ADMIN_API = "/admin-api/v1";

  const config = {
    headers: {
      "Authorization": `Bearer ${roomData["admin-token"]}`,//gwrO1tp!@trY,
      "Content-Type": "application/json",
    }
  };

  axios.get(`${URL}:${PORT}${ADMIN_API}/status`, config)
    .then((response) => {
      setLoading(false);
      setCheckRoomData(response.data);
    })
    .catch((e) => {
      console.log(`error checking if room exists`, e);
    });
}

export function checkRoomUsersLimit(roomData, setCurrentUsersNum) {
  try {
    const URL = `${roomData.server}`;
    const PORT = roomData.port;
    const ADMIN_API = "/admin-api/v1";

    fetch(`https://${URL}:${PORT}${ADMIN_API}/status`,
      {
        method: 'get',
        headers: {
          "Authorization": `Bearer ${roomData["admin-token"]}`,//gwrO1tp!@trY,
          "Content-Type": "application/json",
        }
      })
      .then(response => response.json())
      .then(data => {
        console.log('data.lobbyCounter', data.lobbyCounter);
        if(data.connectedUsers >= 0 && data.lobbyCounter >= 0) {
          const totalUsers = data.connectedUsers + data.lobbyCounter;
          setCurrentUsersNum(totalUsers);
        } else {
          console.log("error retrieving users for room");
        }
      })
      .catch(e => { console.log(`e`, e); });
  } catch (error) {
    console.log(error);
  }
}

export function incrementLobbyUser(body, roomData) {
  try {
    const URL = `${roomData.server}`;
    const PORT = roomData.port;
    const ADMIN_API = "/admin-api/v1";

    fetch(`https://${URL}:${PORT}${ADMIN_API}/users/counter/incr`,
      {
        method: 'post',
        headers: {
          "Authorization": `Bearer ${roomData["admin-token"]}`,//gwrO1tp!@trY,
          "Content-Type": "application/json",
        },
        data: body
      })
      .catch(e => { console.log(`e`, e); });
  } catch (error) {
    console.log(error);
  }
}

