import React, { useState, useRef, useEffect } from "react";

// Libraries
import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import { EffectFade, Mousewheel } from "swiper";

// Router
import { useNavigate } from "react-router-dom";

// Components
import LargePillButton from "../Components/Buttons/LargePillButton";
import HomeCard from "../Components/HomeCard";

// Assets
import video from "../assets/home/heroVideo.mp4";
import unmute from "../assets/unmute.svg";
import mute from "../assets/mute.svg";
import TellyClubWHITE from "../assets/home/Telly-Club-WHITE.svg";

// Styles
import "swiper/swiper-bundle.min.css";

function MainPage() {
  const [, setEndHeader] = useState(false);
  const [disableOverlay, setDisableOverlay] = useState(false);
  const [muted, setMuted] = useState(true);
  const [firstClick, setFirstClick] = useState(true);
  const [isFirst, setIsFirst] = useState(true);
  const [swiper, setSwiper] = useState(null);

  const vidRef = useRef(null);
  const overlayRef = useRef(null);
  const muteRef = useRef(null);
  const unmuteRef = useRef(null);

  const navigate = useNavigate();

  const videoFinished = () => {
    slideTo(1);
  };

  const slideTo = (index) => swiper.slideTo(index);

  const toggleSound = () => {
    if (firstClick) {
      setFirstClick(false);
    }
    muted ? (vidRef.current.muted = false) : (vidRef.current.muted = true);
    setMuted(!muted);
  };

  useEffect(() => {
    if (isFirst) {
      vidRef.current.play();
      setTimeout(() => {
        if (overlayRef && overlayRef.current) {
          overlayRef.current.style.opacity = "0";
        }
      }, 500000);
    } else {
      vidRef.current.pause();
    }
  }, [isFirst]);

  useEffect(() => {
    if (disableOverlay) {
      vidRef.current.muted = false;
      overlayRef.current.style.opacity = "0";
      // muteRef.current.style.display = "none";
    }
  }, [disableOverlay]);

  return (
    <div className={`home-main`}>
      <div className={`home-header-end ${isFirst ? "home-main-gradient" : ""}`}>
        <div className="home-header-end-inner">
          <img src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/e34471fb-035a-484a-a69b-0dd3dad6e6b5/Telly-Club-WHITE.png" alt="" />
          <span className="home-header-button">
            <LargePillButton
              actionDestination="/create-room-now-or-later"
              icon="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/100768/documents/645213fc-2b9c-4711-a415-ca349f3e97f5/right-arrow-with-tail.png"
              text="Enter the Club"
              width="256"
              height=""
            ></LargePillButton>
          </span>
        </div>
      </div>

      <Swiper
        // Install Swiper modules
        modules={[Mousewheel, EffectFade]}
        spaceBetween={20}
        slidesPerView={1}
        direction={"vertical"}
        edgeSwipeDetection={false}
        mousewheel={{ inverted: true }}
        onSwiper={setSwiper}
        onSlideChange={(e) => {
          if (e.realIndex === 0) {
            setIsFirst(true);
          } else {
            setIsFirst(false);
          }
        }}
        onReachBeginning={() => {
          setIsFirst(true);
        }}
        onReachEnd={() => {
          setEndHeader(true);
        }}
        onFromEdge={() => {
          setEndHeader(false);
        }}
      >
        <SwiperSlide>
          <div className="first-slide-container">
            <div className="video-contain">
              <div className="top-half-video"></div>
              <video ref={vidRef} muted="muted" playsInline="playsInline" autoPlay id="hero-video" onEnded={() => videoFinished()}>
                <source src={video} type="video/mp4" />
              </video>

              <div
                className="unmute-icon"
                onClick={() => {
                  firstClick ? setDisableOverlay(true) : () => {};
                  toggleSound();
                }}
              >
                {muted ? <img src={mute} alt="mute" ref={muteRef} /> : <img src={unmute} alt="unmute" ref={unmuteRef} />}
              </div>
            </div>

            <div ref={overlayRef} className="home-main-wrapper">
              <div className="home-main-content">
                <h1 className="home-title">
                  Step into the
                  <img className="tc-logo" src={TellyClubWHITE} alt="React Logo" />
                </h1>
                {/* <div className="home-description-text rainbow rainbow_text_animated" >skip the line + enter the club</div> */}

                <div
                  className="gradient-border gradient-border-large button-size button-size-large"
                  onClick={() => {
                    //navigate("/create-room");
                    navigate("/create-room-now-or-later");
                  }}
                >
                  <div className="button-pill--container">
                    <p className="button-pill--text">Enter the Club</p>
                    <img className="nav-image" src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/100768/documents/645213fc-2b9c-4711-a415-ca349f3e97f5/right-arrow-with-tail.png" alt="button icon" />
                  </div>
                </div>

                <div className="home-description" id="shadowBox">
                  <div
                    className="more-information rainbow rainbow_text_animated"
                    onClick={() => {
                      videoFinished();
                    }}
                  >
                    SCROLL FOR MORE
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide className="first-screen">
          <HomeCard cardTitle="Book your next meeting in a new world and dimension" cardImage="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/0af3028f-1bb0-4271-8549-7e579793a1a7/callout1.png"></HomeCard>
        </SwiperSlide>
        <SwiperSlide className="second-screen">
          <HomeCard cardTitle="Pop in for a 1:1, standup, group hang, or happy hour" cardImage="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/216a33c9-9b87-47c5-8b75-45b1599d54bc/callout3.png"></HomeCard>
        </SwiperSlide>
        <SwiperSlide className="third-screen">
          <HomeCard cardTitle="Dance the day away in an out-of-work experience" cardImage="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/7515ebf4-9f46-4c7d-af0f-21110f96c255/callout2.png"></HomeCard>
        </SwiperSlide>
        <SwiperSlide>
          <div className="home-end-wrapper">
            <div className="home-end-inner">
              <img className="home-end-telly" src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/7/documents/574dbb4b-1fe9-4956-b237-61fd5652d0a5/Telly_Pose_Directors_Chair.png" alt=" Brandlive Telly" />
              <h2 className="home-end-title">
                A{" "}
                <a href="https://www.brandlive.com" className="bl-link" target="_blank" rel="noopener noreferrer">
                  <img src="https://brandlive-upload.s3-us-west-2.amazonaws.com/uploads//uploads/17/documents/3b778d3d-4e99-4ef8-ac70-f5e1c7b2eae9/logo.png" />
                </a>
                Production
              </h2>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default MainPage;
